import { Enum } from '#helpers/Enum.js';

/**
 * NOTE:
 * If doing changes to this Enum and cases, add the changes in core/src/Enums/Portal/PageType.php
 */
export const PageTypeEnum = Enum({
    ANALYTICS_MAP: 'analytics.map',
    ANALYTICS_DASHBOARD: 'analytics.dashboard',
    ANALYTICS_DEVELOPMENT: 'analytics.development',
    ANALYTICS_TABLE: 'analytics.table',
    ANALYTICS_DATA_EXPLORER: 'analytics.data-explorer',
    ANALYTICS_WORD_CLOUD: 'analytics.word-cloud',

    CONTEXT_GLOBAL: 'context.global',
    CONTEXT_INDUSTRY: 'context.industry',
    CONTEXT_COUNTRY: 'context.country',

    ASSISTANT: 'assistant',

    REPORTS: 'reports',

    PLACEHOLDER: 'placeholder',

    SETTINGS_ACTIVITIES: 'settings.activities',
    SETTINGS_ACTIVITY_CATEGORIES: 'settings.activityCategories',

    PROFILE: 'profile',
});
